<template>
    <div>   
        <h2 class="primary darken-2 white--text font-weight-bold subheading pt-1 text-xs-center">Requested Withdrawal History</h2>
        <v-divider></v-divider>
        <div class="" style="max-height: 400px; overflow: auto"> 
            <v-card color="white" style="" v-for="item in requestedWithdrawals"
                :key="item.id"
            >
                <v-card-text class="px-3 py-2 " style="font-size: 12px; cursor: pointer">
                    <h2 class="teal--text" >
                        Status: 
                        <span v-if="item.confirmed" class="blue--text">Validated</span>
                        <span v-else class="red--text">Pending</span>
                    </h2>
                    <span><b>Request:</b> {{item.amount}} FCFA: On {{item.get_date_str}}</span>
                    <v-spacer></v-spacer>
                    <div >
                        <span v-if="item.transaction_operator == 'Topup Withdrawal'"><b>Operator:</b> Via Topup spot withdawal of Hard cash </span>
                        <span v-else><b>Operator:</b> Via {{item.transaction_operator}} into {{item.phone}} </span>
                    </div>
                </v-card-text>
            <v-divider></v-divider>
            </v-card>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                requestedWithdrawals: [],
            }
        },

        methods:{
            getUserRequestedWithdrawal(){
                axios
                    .get('/api/v1/savings/get/user/requested/withdrawals/history/')
                    .then(response => {
                        this.requestedWithdrawals = response.data  // get the data and fill into campaigns
                        console.log(this.requestedWithdrawals)
                    })
                    .catch(error => {
                        console.log(error.response)
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getUserRequestedWithdrawal();
        },
    }
</script>
